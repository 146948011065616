import * as React from 'react';
import clsx from 'clsx';

export interface AlertProps {
  message?: string;
  isOpen?: boolean;
  status?: 'success' | 'error' | 'primary' | 'warning';
}

export const Alert = ({ message = '', isOpen = false, status = 'error' }: AlertProps) => {
  const statusStyles = {
    success: 'bg-green-500 text-white',
    error: 'bg-red-500 text-white',
    primary: 'bg-blue-500 text-white',
    warning: 'bg-yellow-500 text-black'
  };

  const icons = {
    success: (
      <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 1.5a6.5 6.5 0 110 13 6.5 6.5 0 010-13zM6.667 8.333L8 9.667l3.333-3.334M4.667 8L6 9.333"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    error: (
      <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 14.5A6.5 6.5 0 108 1.5a6.5 6.5 0 000 13zm0-9.167v3.334M8 10.667h.007"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    primary: (
      <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 1.5a6.5 6.5 0 110 13 6.5 6.5 0 010-13zm0 2.917v5.166m0 1.667h.007"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    warning: (
      <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 1.5A6.5 6.5 0 108 14.5 6.5 6.5 0 008 1.5zm0 5.333v2.667M8 11.667h.007"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  };

  return (
    <div
      id="alert-message-container"
      className={clsx(
        'fixed top-4 left-1/2 transform -translate-x-1/2 px-4 py-2 rounded-lg shadow-md flex items-center gap-3',
        statusStyles[status],
        isOpen ? 'flex' : 'hidden'
      )}
    >
      {icons[status]}

      <p className="font-medium text-sm">{message}</p>
    </div>
  );
};

export default Alert;