import { Avatar } from 'core/components/Avatar';
import { stringToHslColor } from 'core/utils/color';
import * as React from 'react';
import clsx from 'clsx';
import { Typography } from 'core/components/Typography';
import { TypographyColorProps } from 'core/components/Typography/Typography.types';
import { WaitingIcon } from 'core/icons/Waiting';

export interface INameCellDataTableUsersProps {
  value?: string;
  color?: TypographyColorProps;
  avatar?: {
    backgroundColor: string;
    textColor: TypographyColorProps;
  };
}

export const NameCellDataTableUsers = ({
  value = '',
  color = 'text-grayscale-80',
  avatar = {
    backgroundColor: '',
    textColor: 'text-grayscale-90'
  }
}: INameCellDataTableUsersProps) => {
  if (value.toLowerCase() === 'waiting for acceptance') {
    return (
      <div
        className={clsx(
          'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.75rem]',
          'w-full h-full overflow-hidden'
        )}>
        <WaitingIcon iconSize={'M'} />
        <Typography
          variant={'Medium'}
          size={'S'}
          color={color}
          className={clsx('text-left truncate w-full')}>
          {value}
        </Typography>
      </div>
    );
  }
  const initial =
    value.split(' ').length > 1
      ? `${value.split(' ')[0].charAt(0)}${value.split(' ')[1].charAt(0)}`
      : value.split(' ')[0].charAt(0);

  return (
    <div
      className={clsx(
        'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.75rem]',
        'w-full h-full overflow-hidden'
      )}>
      <Avatar
        variant={'user-placeholder'}
        size={'XS'}
        backgroundColor={avatar?.backgroundColor}
        textColor={avatar.textColor}>
        {initial}
      </Avatar>
      <Typography
        variant={'Medium'}
        size={'S'}
        color={color}
        className={clsx('text-left truncate w-full')}>
        {value}
      </Typography>
    </div>
  );
};
